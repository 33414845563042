<template>
  <section>
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.applyDate') }}</span></div>
           <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />
         </div>
      </div>
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.id') }}</span></div>
           <div>
             <input type="text" class="mr-5" v-model="reqData.adminId" :placeholder="$t('searchArea.id')" />
            <button class="btn-search" type="button" @click="pageSeach">
              <i class="fas fa-search"></i>
            </button>
           </div>
         </div>
      </div>
    </div>
    <div class="main-contents-wrapper">
      <div class="tab-wrap">
        <button type="button" class="tab-item" :class="{ active: currentStatus === '0' }" @click="changeListType" value="0">{{ $t('button.payApply') }}</button>
        <button type="button" class="tab-item" :class="{ active: currentStatus === '2' }" @click="changeListType" value="2">{{ $t('button.payWait') }}</button>
        <button type="button" class="tab-item" :class="{ active: currentStatus === '1' }" @click="changeListType" value="1">{{ $t('button.payComplete') }}</button>
      </div>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody v-if="applyList.length !== 0">
            <tr v-for="(item, idx) in applyList" :key="idx">
              <td>
                <button type="button" class="fc-id btn-link" @click="detailOpen('company', item.regId)">{{ item.regId }}</button>
              </td>
              <td>
                <button v-if="item.calculateIdx" type="button" class="fc-id btn-link" @click="ptDetailOpen(item)">{{ item.calculateIdx }}</button>
                <template v-else> - </template>
              </td>
              <td>
                <span class="money-apply">{{ item.creditTypeText }}</span>
              </td>
              <td>
                <span class="money-apply">{{ numberWithCommas(item.preCreditAmt) }}</span>
              </td>
              <td>
                <span class="money-apply">{{numberWithCommas(item.realCreditAmt) }}</span>
              </td>
              <td>
                <span class="money-apply">{{numberWithCommas(item.creditAmt) }}</span>
              </td>
              <td>{{ item.creditType === '3' ? 0 : Number(item.creditRate).toFixed(2) }}% </td>
              <td>{{ numberWithCommas((Number(item.realCreditAmt) * (Number(item.creditRate) * 0.01)).toFixed(0)) }}</td>
              <td>
                <span>{{ numberWithCommas(Number(item.realCreditAmt) + Number(item.preCreditAmt)) }}</span>
              </td>
              <td style="width: 15%">
                <span>{{ item.regDate }}</span>
              </td>
              <td style="width: 15%">
                <span v-if="item.creditStatus === '1'">{{ item.updDate }}</span>
                <span v-else>-</span>
              </td>
              <td class="statusColor" :class="item.statusClass">{{ item.statusText }}</td>
              <td style="width: 8%" >
                <div class="status-change-btn-wrap">
                  <button class="btn-innerTable btn-status-change wait" type="button" v-if="item.creditStatus == '0'" @click="statusChange(item.creditIdx, item.creditAmt, item.creditType, item.creditStatus)">{{ $t('button.confirm') }}</button>
<!--                  <button class="btn-innerTable btn-status-change refuse" type="button" value="1" :data-list-idx="idx" :data-cash-idx="item.cashIdx" v-if="item.creditStatus == '2'" @click="statusChange(item.creditIdx, item.creditAmt, item.creditType, item.creditStatus, 1)">{{ $t('button.payment') }}</button>-->
<!--                  <button class="btn-innerTable btn-status-change refuse" type="button" value="1" :data-list-idx="idx" :data-cash-idx="item.cashIdx" v-if="item.creditStatus == '2'" @click="statusChange(item.creditIdx, item.creditAmt, item.creditType, item.creditStatus, -1)">{{ $t('button.refu') }}</button>-->
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="10">
                <span class="notice-tableDataEmpty">{{ $t('txt.noData') }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="codeOpen" v-if="codeOpen">
        <h4>
          서브포인트 코드별 적립내역 팝업
          <a @click="ptDetailClose()" class="x"><i class="fa fa-times"></i></a>
        </h4>
        <ul class="head">
          <li>번호</li>
          <li>기준요율</li>
          <li>게임요율</li>
          <li>적용요율</li>
          <li>배팅금</li>
          <li>{{ $t('table.head.winAmt') }}</li>
          <li>차감크레딧</li>
          <li>지급서브포인트</li>
          <li>{{ $t('table.head.date') }}</li>
        </ul>

        <template v-if="detailList.length">
          <template v-for="(item, idx) in detailList">
            <ul class="list">
              <li>{{ (detailPageInfo.page - 1) * 10 + (idx + 1) }}</li>
              <li>{{ item.baseRate }}</li>
              <li>{{ item.gameRate }}</li>
              <li>{{ item.creditSpRate }}</li>
              <li>{{ numberWithCommas(item.betAmt) }}</li>
              <li>{{ numberWithCommas(item.betWinAmt) }}</li>
              <li>{{ numberWithCommas(item.creditAmt) }}</li>
              <li>{{ numberWithCommas(item.creditSubPointAmt) }}</li>
              <li>{{ replaceDateT(item.regDate) }}</li>
            </ul>
          </template>
        </template>
        <template v-else>
          <ul class="list">
            <li>{{ $t('txt.noData') }}</li>
          </ul>
        </template>
        <pagination v-if="detailList.length !== 0" @goPage="getDetailList" :pageNum="detailPageInfo.page" :pageSize="detailPageInfo.count_per_list" :totalCount="detailPageInfo.tatal_list_count" />
      </div>
    </div>
    <pagination v-if="applyList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import { creditUpdateStatus } from '@/api/member.js'
import { creditBotList } from '@/api/company.js'
import { getDateStr, replaceDateT } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import UiCheck from '@/components/ui/UiCheckSet'
import { creditDetail } from '@/api/partner'
import { getSiteData } from '@/libs/auth-helper'

export default {
  name: 'CreditApplyList',
  components: {
    TableHead,
    DateSelector,
    SearchFilter,
    Pagination,
    UiCheck
  },
  props: {
    status: {
      type: String,
      default: '0'
    }
  },
  watch: {
    $route: {
      async handler () {
        // this.currentStatus = this.$route.params.status || this.$route.query.status
        // await this.setTableData(1);
      }
    },
    tabStatus: {
      async handler (status) {
        // console.log(status)
        // if (status === '1') {
        //   this.headInfo.dataList = this.postDataList
        // } else {
        //   this.headInfo.dataList = this.preDataList
        // }
      }
    }
  },
  data: function () {
    return {
      siteInfo: null,
      codeOpen: false,
      tableName: 'Basic',
      headInfo: {
        fstColumn: false,
        dataList: ['companyId', 'onecode', 'statusPT', 'prePT', 'realCreditPT', 'applyPT', 'feeRate', 'feeAmt', 'postPT', 'applyDate', 'updDate', 'status', 'option'],
      },
      postDataList: ['companyId', 'onecode', 'prePT', 'applyPT', 'realCreditPT', 'feeRate', 'feeAmt', 'postPT', 'applyDate', 'updDate', 'status'],
      preDataList: ['companyId', 'onecode', 'prePT', 'applyPT', 'feeRate', 'feeAmt', 'postPT', 'applyDate', 'status', 'option'],
      reqData: {
        startDate: '',
        endDate: '',
        creditStatus: '0',
        page: 1,
        count_per_list: '30',
        adminId: '',
        creditType: '1'
      },
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      pageInfo: {},
      applyList: [],
      checkList: [
        {
          val: '0',
          text: '요청'
        },
        {
          val: '1',
          text: '대기'
        },
        {
          val: '2',
          text: '완료'
        }
      ],
      tabStatus: 0,
      currentStatus: '',
      detailList: [],
      detailPageInfo: {},
      detailInfo: {
        page: 1
      }
    }
  },
  methods: {
    replaceDateT,
    async ptDetailOpen (item) {
      this.detailInfo = item
      await this.getDetailList(1)
    },
    async ptDetailClose () {
      this.detailInfo = null
      this.detailList = []
      this.codeOpen = false
    },
    async getDetailList (page) {
      if (!page) {
        page = 1
        this.detailPageInfo.page = page
      }
      const params = {
        siteId: this.detailInfo.siteId,
        calculateIdx: this.detailInfo.calculateIdx,
        page
      }
      this.detailList = []

      await creditDetail(params).then(result => {
        console.log(result)
        if (result.resultCode === '0') {
          this.detailList = result.data.list
          this.detailPageInfo = result.data.pageInfo

          this.codeOpen = true
        }
      })
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    pageSeach () {
      this.setTableData(1)
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    async getCreditApplyList (pageNum) {
      if (pageNum) {
        this.reqData.page = pageNum
      }
      this.reqData.creditStatus = this.currentStatus
      const data = this.reqData
      return await creditBotList(data)
    },
    async changeListType (evt) {
      const target = evt.target
      let chooseType = target.value
      this.currentStatus = chooseType
      this.tabStatus = chooseType
      if (chooseType === '') {
        chooseType = 'all'
      }
      this.$router.replace({ params: {}, query: { status: chooseType } })
    },
    async setTableData (pageNum, status) {
      if (status) {
        this.currentStatus = status
      }
      const res = await this.getCreditApplyList(pageNum)
      const applyList = res.data.list
      const pageInfo = res.data.pageInfo
      if (pageInfo) {
        this.pageInfo = pageInfo
      }
      this.applyList = this.dataConvertor(applyList)
      console.log(this.applyList)
    },
    creditPayment (dataObj) {
      const _creditAmt = prompt('알 지급 금액', dataObj.creditAmt)
      const trigger = {
        msg: '',
        prog: false
      }
      if (_creditAmt) {
        if (isNaN(_creditAmt)) {
          trigger.msg = '숫자만 입력해주세요.'
        } else if (_creditAmt <= 0) {
          trigger.msg = '지급 최소 금액은 0 이상 입니다. 다시 입력해주세요.'
        } else if (_creditAmt == null || _creditAmt == undefined) {
          trigger.msg = ''
        } else {
          trigger.prog = confirm(`${this.numberWithCommas(_creditAmt)}을 지급합니다.`)
          if (trigger.prog) {
            trigger.msg = _creditAmt
          } else {
            trigger.msg = '알 지급 취소'
          }
        }
      } else {
        trigger.msg = ''
        trigger.prog = false
      }

      return trigger
    },
    async statusChange (idx, creditAmt, type, status, nextStatus) {
      const reqData = {
        creditIdx: idx,
        creditType: type,
        creditStatus: status,
        realCreditAmt: creditAmt
      }
      switch (status) {
        case '0':
          reqData.creditStatus = '2'
          break
        case '2':
          reqData.creditStatus = nextStatus.toString()
          if (type === '1' || type === '-1') {
            const preCheck = this.creditPayment(reqData)
            if (preCheck.prog) {
              reqData.realCreditAmt = preCheck.msg
            } else {
              if (preCheck.msg) {
                alert(preCheck.msg)
              }
              return
            }
          }
          break
        case '1':
          (reqData.creditStatus = '1'), (reqData.creditType = '-1')
          reqData.realCreditAmt = '0'
          break
      }
      const res = await creditUpdateStatus(reqData)
      this.setTableData()
      if (res.resultCode === '0') {
        if (reqData.creditStatus === '1') {
          alert('알 지급 완료')
        } else {
          alert('알 상태 변경 완료')
        }
      } else {
        if (reqData.creditStatus === '1') {
          alert('알 지급에 실패 했습니다. 다시 시도해주세요.')
        } else {
          alert('알 상태 변경에 실패 했습니다. 다시 시도해주세요.')
        }
      }
    },
    dataConvertor (dataList) {
      const _dataList = dataList
      if (!dataList.length || dataList.length === 0) {
        return _dataList
      }
      _dataList.forEach(items => {
        const creditStatus = items.creditStatus
        let statusText = ''
        // - 0:요청, 1:완료, 2:대기 , -1"거부
        if (creditStatus === '0') {
          statusText = '지급신청'
          items.statusClass = 'apply'
        } else if (creditStatus === '1') {
          statusText = '지급완료'
          items.statusClass = 'approve'
        } else if (creditStatus === '2') {
          statusText = '입금대기'
          items.statusClass = 'wait'
        } else if (creditStatus === '-1') {
          statusText = '지급거절'
          items.statusClass = 'refuse'
        }
        if (!items.realCreditAmt) {
          items.realCreditAmt = '-'
        }


        switch (items.creditType) {
          case '1':
            items.creditTypeText = '지급신청'
            break
          case '-1':
            items.creditTypeText = '환급신청'
            break
          case '2':
            items.creditTypeText = '포인트전환'
            break
          case '3':
            items.creditTypeText = '포인트현금수령'
            break
          case '5':
            items.creditTypeText = '수동지급'
            break
          case '-5':
            items.creditTypeText = '수동회수'
            break
        }

        items.statusText = statusText
        items.regDate = items.regDate.replace('T', ' ')
        items.updDate = items.updDate.replace('T', ' ')
        for (const item in items) {
          if (items[item] === '') {
            items[item] = '-'
          }
        }
      })
      return _dataList
    }
  },
  async created () {
    this.siteInfo = this.getSiteInfo()
    this.currentStatus = this.$route.query.status || this.status
    this.tabStatus = this.currentStatus
    if (this.currentStatus === 'all') {
      this.currentStatus = ''
    }
    this.setStartDate()
    this.setEndDate()
    await this.setTableData()
  }
}
</script>

<style scoped>
.statusColor {
  font-weight: 700;
}
.statusColor.apply {
  color: #ef8c1d;
}
.statusColor.approve {
  color: blue;
}
.statusColor.refuse {
  color: red;
}
.statusColor.wait {
  color: green;
}
.codeOpen {
  min-width: 1000px;
  width: 80%;
  max-height: 850px;
  background: #fff;
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 30px 40px;
  border: 1px solid #353535;
  overflow: auto;
}
.codeOpen > h4 {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.codeOpen .x {
  font-size: 30px;
  cursor: pointer;
}
.codeOpen ul {
  display: flex;
  font-size: 14px;
}
.codeOpen li {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.codeOpen .head li {
  background: #353535;
  color: #fff;
}
.codeOpen .list li {
  border-bottom: 1px solid #353535;
}
</style>
